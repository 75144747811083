import { Button, makeStyles } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import "./Home.css";
import GridTableComponent from "../../components/GridTableComponent/GridTableComponent";
import axios from "axios";

const Home = ({user, setUser}) => {
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(0);
  const [thisMonth, setThisMonth] = useState(0);
  const [priviousMonth, setPriviousMonth] = useState(0);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  useEffect(async() => {
      try {
          const { data } = await axiosInstance.get("api/auth/config",{
              headers: {
                  token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
              },
            });
          setTotal(data.total);
          setThisMonth(data.thisMonth);
          setPriviousMonth(data.previousMonth);
          return data;
      } catch (e) {
          console.error(e);
          if (e.response.status === 403) {
              window.localStorage.removeItem('user');
              setUser(null);
              history.push("/login");
          }
          return null
      }
  }, []);


  return (
    <div>
      <div className="content">
        <div>
          <p><span>Lifetime: {total}$</span></p>
          <p><span>This month: {thisMonth}$</span></p>
          <p><span>Previous month: {priviousMonth}$</span></p>
        </div>
        <br></br>
        <GridTableComponent
          setUser={setUser}
        />
      </div>
    </div>
  );
};

export default Home;
