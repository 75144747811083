import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="footer"
    >
      <div className="footer-content">
        <div className="footer-logo">
        <Link to="/">
            <img src={"logo-footer-coding.png"} alt="image" className="logo-image-footer" loading="lazy" width="231" height="105"/>
        </Link>
        </div>
        <div>
          <p className="XB">XB</p>
        </div>
      </div>
      <div className="rights">
        <p>© 2023 iscodingforyou.com. {t("All rights reserved")}</p>
      </div>
    </div>
  );
};

export default Footer;
