import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import "./TestDetails.css";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const TestDetails = () => {
  const [questions, setQuestions] = useState();
  const history = useHistory();


  const { id } = useParams();
  useEffect(async() => {
    window.scrollTo(0, 0)
    try {
        const { data } = await axiosInstance.get("api/auth/test-details/"+id,{
            headers: {
                token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
            },
          });
        setQuestions(data);
        return data;
    } catch (e) {
        console.error(e);
        return null
    }
  }, [id]);


  const handleSendTestAgain = async () => {
    try {
      const response = await axiosInstance.post("api/auth/send-test-again",{
        testId: id
      }, {
        headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      if (response.data.success) {
        alert( "Success" )
      } else {
        alert( "Failed, somethig went wrong.")
      }
    } catch (err) {
      console.log(err, "err")
      alert( "Error")
    }
  };

  return (
    <div>
      <div className="content-stat">
        {questions &&
        questions.map((question, i) => (
          <div key={i} className="question-wrap">
            {!question.image ? (
              <>
                <div className="question-title">
                {question.questionId}
                </div>
                {question.options.map((option, index) => (
                  <div key={index} className={option === question.correctAnswer ? "answer-wrap answer-wrap-correct" : "answer-wrap"}>
                    <div className="answer-title">
                      {option}
                    </div>
                    {option === question.customerAnswer ||
                     (option === "mostly yes" && question.customerAnswer === "yes") ||
                     (option === "mostly no" && question.customerAnswer === "no") ? (
                      <div className="answer-count">
                        X
                      </div>
                    ):(
                      <>
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="question-title">
                <img src={"/questions/" + question.questionId} alt="image" width="100px" height="100px" className="single-question-image"/>
                </div>
                {question.options.map((option, index) => (
                  <div key={index} className={option === question.correctAnswer ? "answer-wrap answer-wrap-correct" : "answer-wrap"}>
                    <div className="answer-title">
                      <img src={"/questions/" + option} alt="image" width="50px" height="50px" className="single-question-image" loading="lazy" />
                    </div>
                    {option === question.customerAnswer ? (
                      <div className="answer-count">
                        X
                      </div>
                    ):(
                      <>
                      </>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        ))}                 
      </div>
      <div className="details-actions">
        <Button
          variant="contained"
          onClick={handleSendTestAgain}
        >
          Send test again
        </Button>
      </div>
    </div>
  );
};

export default TestDetails;
