import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import "./StatisticQuestion.css";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const StatisticQuestion = () => {
  const [questions, setQuestions] = useState([]);


  useEffect(async() => {
    window.scrollTo(0, 0)
    try {
        const { data } = await axiosInstance.get("api/auth/statistic-questions",{
            headers: {
                token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
            },
          });
        setQuestions(data);
        console.log(data);
        return data;
    } catch (e) {
        console.error(e);
        return null
    }
  }, []);

  return (
    <div>
      <div className="content-stat">
        {questions &&
        questions.map((question, i) => (
          <div key={i} className="question-wrap">

          {!question.image ? (
            <>
              <div className="question-title">
              {question.questionId}
              </div>
              {question.answers.map((answer, index) => (
                <div key={index} className={answer.title === question.correctAnswer ? "answer-wrap answer-wrap-correct" : "answer-wrap"}>
                  <div className="answer-title">
                    {answer.title}
                  </div>
                  <div className="answer-count">
                    {answer.count}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="question-title">
              <img src={"questions/" + question.questionId} alt="image" width="100px" height="100px" className="single-question-image" loading="lazy" />
              </div>
              {question.answers.map((answer, index) => (
                <div key={index} className={answer.title === question.correctAnswer ? "answer-wrap answer-wrap-correct" : "answer-wrap"}>
                  <div className="answer-title">
                    <img src={"questions/" + answer.title} alt="image" width="50px" height="50px" className="single-question-image" loading="lazy" />
                  </div>
                  <div className="answer-count">
                    {answer.count}
                  </div>
                </div>
              ))}
            </>
          )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticQuestion;
