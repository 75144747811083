import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./Pages/Home/Home";
import StatisticQuestion from "./Pages/StatisticQuestion/StatisticQuestion";
import TestDetails from "./Pages/TestDetails/TestDetails";
import Login from "./Pages/Login/Login";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUser(JSON.parse(window.localStorage.getItem('user')));
    }
  }, []);

  useEffect(() => {
    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  return (
    <BrowserRouter>
      <div className="app">
        <Header
          user={user}
          setUser={setUser}
        />
        <Switch>
        <Route path="/login">{user ? <Redirect to="/" /> : <Login user={user} setUser={setUser} />}</Route>
        {user && (
          <>
            <Route path="/" exact>
              <Home
              user={user}
              setUser={setUser}
              />
            </Route>
            <Route path="/statistic-question.html">
              <StatisticQuestion/>
            </Route>
            <Route path="/test-details.html/:id">
              <TestDetails/>
            </Route>
          </>
        )}
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
