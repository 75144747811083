import React from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import "./GridTableComponent.css";

const viewButton = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    const history = useHistory();

    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <Button onClick={(e) => history.push(`/test-details.html/${data._id}`)}>view</Button>
        </div>
    )
}

const viewEmail = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
        <div className={data.paid ? 'paid-result rgt-cell-inner' : 'rgt-cell-inner'}>
            {data.email}
        </div>
    )
}

const columns = [
    {
        id: 1, 
        field: 'email', 
        label: 'Email',
        cellRenderer: viewEmail
    }, 
    {
        id: 2, 
        field: 'name', 
        label: 'Name',
    },
    {
        id: 3, 
        field: 'resultLevel', 
        label: 'Result Level'
    },
    {
        id: 4, 
        field: 'country', 
        label: 'Country',
    },
    {
        id: 5, 
        field: 'price', 
        label: 'Price',
    },
    {
        id: 6, 
        field: 'createdAt', 
        label: 'Created at',
    },
    {
        id: 7, 
        field: 'age', 
        label: 'Age',
    },
    {
        id: 8, 
        field: 'paid', 
        label: 'Paid',
    },
    {
        id: 9, 
        field: 'emailSent', 
        label: 'Email sent',
    },
    {
        id: 10, 
        field: 'rimemberEmailSent', 
        label: 'Remember email',
    },
    {
        id: 11, 
        field: 'score', 
        label: 'Score',
    },
    {
        id: 12, 
        field: 'scorePersonality', 
        label: 'Score personality',
    },
    {
        id: 13, 
        field: 'scorePersonalityManagment', 
        label: 'Score pers mng',
    },
    {
        id: 14, 
        field: 'scorePersonalityQa', 
        label: 'Score pers QA',
    },
    {
        id: 15, 
        field: 'scoreEnglish', 
        label: 'Score english',
    },
    {
        id: 16, 
        field: 'translateLanguage', 
        label: 'Language',
    },
    {
        id: 17, 
        field: 'view', 
        label: 'View',
        cellRenderer: viewButton,
    }, 
    // {
    //     id: 3, 
    //     field: 'last_visited', 
    //     label: 'Last Visited',
    //     sort: ({ a, b, isAscending }) => {
    //         let aa = a.split('/').reverse().join(),
    //         bb = b.split('/').reverse().join();
    //         return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
    //     }
    // },
    // {
    //     id: 4, 
    //     field: 'test', 
    //     label: 'Score',
    //     getValue: ({ value }) => value.x + value.y
    // }
];

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const GridTableComponent = ({setUser}) => {
    const [tests, setTests] = useState([]);
    const history = useHistory();

    useEffect(async() => {
        try {
            const { data } = await axiosInstance.get("api/auth/tests",{
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
                },
              });
            setTests(data);
            return data;
        } catch (e) {
            console.error(e);
            if (e.response.status === 403) {
                window.localStorage.removeItem('user');
                setUser(null);
                history.push("/login");
            }
            return null
        }
    }, []);

    return (
    <GridTable columns={columns} rows={tests} />
    );
};

export default GridTableComponent;
