import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Login.css";
import { useHistory } from "react-router";

const Login = ({user, setUser}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const handleLogin = async () => {
    // dispatch(loginStart());
    try {
      const res = await axiosInstance.post("api/login", { 
        email: email,
        password: password
       });
      console.log(res);
      if(res.status === 200 && res.data.email) {
        setUser(res.data)
        history.push("/");
      }
    } catch (err) {
      console.log(err, "err")
      // dispatch(loginFailure());
    }
  };
 
  return (
     <div className="login">
        <h1 className="home-title-en"> Login </h1>

        <form className="loginForm">
          {/* <input
            type="text"
            placeholder="email"
            className="loginInput"
            onChange={(e) => setEmail(e.target.value)}
          /> */}
          <TextField id="outlined-basic" className="login-field" label="email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
          {/* <input
            type="password"
            placeholder="password"
            className="loginInput"
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <TextField type="password" id="outlined-basic" className="login-field" label="password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
          <Button
            className="loginButton login-field"
            onClick={handleLogin}
            // disabled={isFetching}
          >
            Login
          </Button>
      </form>
             
        {/* <Link to="/" className="home-link">
          <Button
            style={{
              border: "3px solid #030E4F",
              backgroundColor: "#FFD99F",
              color: "#030E4F",
              marginTop: "30px",
              padding: "15px 30px",
              maxWidth: "250px",
              margin: "auto",
              fontWeight: "bold"
            }}
            variant="text"
            size="medium"
            >
            {t("Home page")}
          </Button>
        </Link> */}
 </div>
  );
};

export default Login;
