import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Header.css";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";

const Header = ({user, setUser}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = (event) => {
    event.preventDefault()
    setUser(null);
    localStorage.removeItem('user');
    history.push("/login");
  };

  return (
    // <div className="header" style={{display: alowTestButton ? 'block' : 'none'}}>
    <div className="header">
      <div className="header-wrap">
        <div className="logo">
          <NavLink to="/">
            <img src={"logo-coding-1.png"} alt="image" className="logo-image app-image" loading="lazy" width="199" height="89"/>
          </NavLink>
        </div>

        {user ? (
          <>
            <NavLink to="/statistic-question.html" className="menu-logout" exact activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                className="menu-button-custom"
              >
                Stat ques
              </Button>
            </NavLink>
            <Button
                variant="outlined"
                size="medium"
                className="menu-logout"
                onClick={(e) => handleLogout(e)}
              >
              LOGOUT
            </Button>
          </>
        ):(
          <div/>
        )}

        {/* {showLanguage ? (
          <div className="language-list">
            <Select
              value={lng}
              onChange={changeLanguageHandler}
              inputProps={{ 'aria-label': 'Without label' }}
              disableUnderline
            >
              <MenuItem value={"en"}><img src="/locale/flags/en.svg" alt="American Flag" className="lngFlag" width="28px" height="19.47"/>{" "}English</MenuItem>
              <MenuItem value={"fr"} disabled><img src="/locale/flags/fr.svg" alt="Français Flag" className="lngFlag" width="28px" height="19.47"/>{" "}Français</MenuItem>
              <MenuItem value={"sp"} disabled><img src="/locale/flags/sp.svg" alt="Flag" className="lngFlag" width="28px" height="19.47"/>{" "}Español</MenuItem>
              <MenuItem value={"rs"}><img src="/locale/flags/rs.svg" alt="Flag" className="lngFlag" width="28px" height="19.47"/>{" "}Srpski</MenuItem>
              <MenuItem value={"de"} disabled><img src="/locale/flags/de.svg" alt="Flag" className="lngFlag" width="28px" height="19.47"/>{" "}Deutsch</MenuItem>
            </Select>
          </div>
        ) : (
          <div className="language-list">
          </div>
        )} */}

        <div className="nav-menu-burger">
          {/* <Menu
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            styles={styles}
            right >
            <NavLink to="/" exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("Home")}
              </Button>
            </NavLink>
            <NavLink to="/about.html" exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("About")}
              </Button>
            </NavLink>
            <NavLink to="/blog.html" exact className="menu-button-burger" activeStyle={{ borderBottom: "1px solid #F49F1C" }}>
              <Button
                style={{
                  minWidth: "130px",
                  color: "#F49F1C"
                }}
                variant="text"
                size="medium"
                onClick={closeSideBar}
              >
                {t("Blog")}
              </Button>
            </NavLink>
            <Button
              style={{
                display: alowTestButton ? 'block' : 'none',
                backgroundColor: '#dfe3e6'
              }}
              variant="outlined"
              size="medium"
              className="menu-button-burger-testing"
              disabled={!alowTestButton}
              onClick={(e) => handleOpenModalAndCloseManu(e)}
            >
              {t("Start testing")}
            </Button>
          </Menu> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
